html,
body {
	height: 100%;
	margin: 0;
}

.country:hover {
	cursor: pointer;
}

.logoContainer {
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: center;
}

.mainTitle {
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-weight: 700;
	margin-left: 20px;
	margin-right: 20px;
}

#mainGroup {
	filter: drop-shadow(0px 60px 40px rgba(0, 0, 0, 0.18));
}

.chartSubtitle {
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-size: 18px;
	opacity: 0.8;
	margin-top: 10px;
	margin-left: 50px;
	margin-right: 50px;
}

.footerMsg {
	font-size: 14px;
	margin-left: 10px;
}

.content-map {
	height: 100%;
	width: 100%;
}

.content-map svg {
	width: 100%;
	overflow: visible;
}

.example {
	text-anchor: end;
	fill: grey;
	text-decoration: underline;
	text-decoration-style: dotted;
	cursor: help;
}

.loading {
	display: flex;
	align-items: center;
	justify-content: space-around;
	font-size: 1.5rem;
	width: 100%;
}

.chartSubtitle.filter {
	margin-top: 10px;
	font-size: 1.25rem;
	font-weight: 700;
}

#root {
	height: 100%;
}

.viz-wrapper {
	height: 100%;
}

.container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.container .row.header {
	flex: 0 1 auto;
	font-size: 32px;
	margin-top: 20px;
}

.container .row.content {
	flex: 1 1 auto;
	display: flex;
	flex-direction: row;
}

.container .row.footer {
	flex: 0 1 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
}

.hidden {
	display: none;
}

.highlight {
	fill: #e50063;
}

.ri-tooltip {
	position: absolute;
	bottom: 20px;
	right: 20px;
	width: 400px;
	padding: 20px 30px;
	z-index: 1300;
	visibility: hidden;
	pointer-events: none;
}
